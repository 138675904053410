import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Head from "../components/head";
import { GatsbyImage } from "gatsby-plugin-image";

export const query = graphql`
	query ($id: String!) {
		contentfulAuthor(id: { eq: $id }) {
			firstName
			id
			fullName
			lastName
			intro
			profileImage {
				gatsbyImageData
				title
			}
		}
		allContentfulBlogPost(filter: { author: { id: { eq: $id } } }) {
			edges {
				node {
					id
					title
					slug
				}
			}
		}
		site {
			siteMetadata {
				title
				siteUrl
			}
		}
	}
`;

const AuthorPage = (props) => {
	const posts = props.data.allContentfulBlogPost;
	const author = props.data.contentfulAuthor;

	return (
		<Layout>
			<Head
				title={`${author.fullName} - author on ${props.data.site.siteMetadata.title}`}
				description={author.intro}
				url={`${props.data.site.siteMetadata.siteUrl}/author/${author.slug}`}
			/>

			<div className="max-w-4xl mx-auto">
				<div className="flex flex-row my-4">
					<div className="">
						<GatsbyImage
							className="w-12 rounded-full"
							image={author.profileImage.gatsbyImageData}
							alt={author.fullName}
						/>
					</div>
					<div className="ml-2">
						<p className="text-lg">{author.fullName}</p>
					</div>
				</div>

				<p className="font-bold">
					Here are some of the things {author.firstName} has written about:
				</p>
				<ul className="list-disc pl-4">
					{posts.edges.map((edge) => {
						return (
							<li key={edge.node.id}>
								<Link to={`/okr-basics/${edge.node.slug}/`}>
									{edge.node.title}
								</Link>
							</li>
						);
					})}
				</ul>
			</div>
		</Layout>
	);
};

export default AuthorPage;
